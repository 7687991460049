import React, { ReactNode, useCallback, useState } from 'react';

interface CardDataStatsProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    total: string;
    rate: string;
    levelUp?: boolean;
    levelDown?: boolean;
    // children: ReactNode;
    icon: string;
    active: boolean
}

const CardDataStats: React.FC<CardDataStatsProps> = ({
    title,
    total,
    // children,
    icon,
    active, ...rest
}) => {    
    return (
        <div {...rest}
         className={`w-[150px] shadow-xl border-gray-400 rounded-xl hover:border-blue-600 cursor-pointer border border-stroke p-6 shadow-default dark:border-strokedark dark:bg-boxdark ${active?'bg-blue-600 text-white':'bg-white'}`}>
            <div className="bg-gray-500 text-center py-1 text-white rounded-md">
                {title}
            </div>

            <div className="mt-4 flex items-center justify-between">
                {/* {children} */}
                <i className={icon + " text-primary"}></i>
                <span className={`text-sm font-medium ${active?'':'text-gray-400'}`}>{total}</span>
            </div>
        </div>
    );
};

export default CardDataStats;
