import Tabs from './Widgets/Tabs'
import Shortcuts from './Widgets/Shortcuts'
import MyApps from './Widgets/MyApps'
import Indicateur from './Widgets/Indicateur'
import Actuality from './Widgets/Actuality'

const Home = () => {
    return (
        <div className="px-5">
            <Tabs />
            <h3 className="text-[50px] mt-7">Bienvenue sur { process.env.REACT_APP_NAME } ...</h3>
            <div className="mt-4 grid grid-cols-12 md:mt-6 2xl:mt-7.5">
                <div className="col-span-12 xl:col-span-8">
                    <Shortcuts />
                    <MyApps />
                    <Indicateur />
                </div>
                <div className="col-span-12 xl:col-span-4">
                    <div className="mt-[4.2rem]">
                    <Actuality/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home