import React from 'react'
interface TitleProps {
  text: string
}
const Title: React.FC<TitleProps> = ({ text }) => {
  return (
    <>
      <h3 className="font-bold text-xl mt-6 mb-3">{text}</h3>
      <hr className="mb-5"/>
    </>
  )
}

export default Title