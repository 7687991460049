const Actuality = () => {
    return (
        <div className="bg-white border p-5 border-stroke cursor-pointer rounded-2xl dark:bg-gray-800 dark:border-gray-700">
            <h4 className="mb-5 font-bold text-xl">Actualités</h4>
            <div className="grid gap-3 grid-cols-12 mb-5">
                <div className="col-span-4">
                    <img className="w-full h-full object-cover object-center" src="https://static0.cbrimages.com/wordpress/wp-content/uploads/2021/02/Jujutsu-Kaisen-Kyoto-Tournament.jpg" alt="" />
                </div>
                <div className="col-span-8 p-2">
                    <h3 className="font-bold mb-3">Titre de l'article</h3>
                    <p className="text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, qui.</p>
                </div>
            </div>
            <div className="grid gap-3 grid-cols-12 mb-5">
                <div className="col-span-4">
                    <img className="w-full h-full object-cover object-center" src="https://static0.cbrimages.com/wordpress/wp-content/uploads/2021/02/Jujutsu-Kaisen-Kyoto-Tournament.jpg" alt="" />
                </div>
                <div className="col-span-8 p-2">
                    <h3 className="font-bold mb-3">Titre de l'article</h3>
                    <p className="text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, qui.</p>
                </div>
            </div>
            <div className="grid gap-3 grid-cols-12 mb-5">
                <div className="col-span-4">
                    <img className="w-full h-full object-cover object-center" src="https://static0.cbrimages.com/wordpress/wp-content/uploads/2021/02/Jujutsu-Kaisen-Kyoto-Tournament.jpg" alt="" />
                </div>
                <div className="col-span-8 p-2">
                    <h3 className="font-bold mb-3">Titre de l'article</h3>
                    <p className="text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, qui.</p>
                </div>
            </div>
            <div className="grid gap-3 grid-cols-12">
                <div className="col-span-4">
                    <img className="w-full h-full object-cover object-center" src="https://static0.cbrimages.com/wordpress/wp-content/uploads/2021/02/Jujutsu-Kaisen-Kyoto-Tournament.jpg" alt="" />
                </div>
                <div className="col-span-8 p-2">
                    <h3 className="font-bold mb-3">Titre de l'article</h3>
                    <p className="text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, qui.</p>
                </div>
            </div>
        </div>
    )
}

export default Actuality