import { useCallback, useEffect, useState } from "react";
import app1 from "../../../assets/app1.jpeg";
import app2 from "../../../assets/app2.jpeg";
import app3 from "../../../assets/app3.jpeg";
import Title from "../../common/Title";
import axios from "axios";

interface ShortcutsItemType {
    id: number,
    appName: string,
    description: string,
    image: string,
    link: string
}

const items: ShortcutsItemType[] = []
const MyApps = () => {
    const [applications, setApplications] = useState<ShortcutsItemType[] | null>(items)
    // const updateCurrentItem = useCallback(
    //     (item: ShortcutsItemType) => {
    //         setcurrent(item)
    //     },
    //     [setcurrent],
    // );
    useEffect(() => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + '/api/dashboard?type=application',
            method: "get",
            headers:{
                "Access-Control-Allow-Origin":"*",
            }
        }).then((response) => {
              console.log(response);
              setApplications(response.data.data);
           })
           .catch((err) => {
              console.log(err.message);
           });
     }, []);
    return (
        <div className="mt-[60px]">
            <Title text="Vos applications" />
            <div className="flex flex-wrap gap-4 max-w-full">   
                {   applications?.map((application) => (
                        <a href={application.link} target="_blank" key={application.id} rel="noreferrer">
                            <div className="bg-white shadow-xl border-green-400 border border-stroke  cursor-pointer rounded-lg hover:border-blue-600 dark:bg-gray-800 dark:border-gray-700">
                                <div className="p-2">
                                    { application.appName == "CAISSE"? (
                                        <img src={app1} alt="" srcSet="" className="w-[60px] h-[60px] object-cover object-center" />
                                        ):""
                                    }
                                    { application.appName == "LOGISTIQUES"? (
                                        <img src={app2} alt="" srcSet="" className="w-[60px] h-[60px] object-cover object-center" />
                                        ):""
                                    }
                                    { application.appName == "RH"? (
                                        <img src={app3} alt="" srcSet="" className="w-[60px] h-[60px] object-cover object-center" />
                                        ):""
                                    }
                                    { application.appName == "PILOTAGE"? (
                                        <img src={app3} alt="" srcSet="" className="w-[60px] h-[60px] object-cover object-center" />
                                        ):""
                                    }
                                         
                                </div>
                            </div>
                        </a>
                            
                    ))
                }
            </div>
        </div>
    )
}

export default MyApps