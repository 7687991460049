import img from "../../../assets/chart.png"
import Title from '../../common/Title'
const Indicateur = () => {
    return (
        <div className="mt-[60px]">
            <Title text="Les indicateurs clés" />
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 xl:grid-cols-3 2xl:gap-7.5 mb-6">
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-purple-100 from-purple-500 rounded-lg to-purple-600 p-6 text-white">
                    <h3 className="font-bold mb-3 text-2xl">Revenu</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-green-100 from-green-500 rounded-lg to-green-600 p-6 text-white">
                    <h3 className="font-bold mb-3 text-2xl">Revenu</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-green-100 rounded-lg p-6 text-black">
                    <h3 className="font-bold mb-3 text-2xl">Revenu</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                </div>
            </div>
            <div>
                <img src={img} alt="" srcSet="" className="w-full object-fill object-center" />
            </div>
        </div>
    )
}

export default Indicateur